"use strict";

var _interopRequireDefault = require("/Users/lingo/projects/changzhen/stylus-manager/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getProductAttrList = getProductAttrList;
exports.saveProductAttrList = saveProductAttrList;
exports.create = create;
exports.update = update;
exports.upload = upload;
exports.del = del;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/product/list',
    method: 'get',
    params: params
  });
}

function getProductAttrList(id) {
  return (0, _request.default)({
    url: '/product/' + id + '/attr/list',
    method: 'get'
  });
}

function saveProductAttrList(id, data) {
  return (0, _request.default)({
    url: '/product/' + id + '/attr',
    method: 'post',
    data: data
  });
}

function create(data) {
  return (0, _request.default)({
    url: '/product',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/product/' + id,
    method: 'put',
    data: data
  });
}

function upload(data) {
  return (0, _request.default)({
    url: '/product/img/upload',
    method: 'post',
    data: data
  });
}

function del(id) {
  return (0, _request.default)({
    url: '/product/' + id,
    method: 'delete'
  });
}