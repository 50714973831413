"use strict";

var _interopRequireDefault = require("/Users/lingo/projects/changzhen/stylus-manager/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDetail = getDetail;
exports.create = create;

var _request = _interopRequireDefault(require("@/utils/request"));

function getDetail() {
  return (0, _request.default)({
    url: '/dictionary',
    method: 'get'
  });
}

function create(data) {
  return (0, _request.default)({
    url: '/dictionary',
    method: 'post',
    data: data
  });
}