"use strict";

var _interopRequireDefault = require("/Users/lingo/projects/changzhen/stylus-manager/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/lingo/projects/changzhen/stylus-manager/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name']))
};
exports.default = _default;