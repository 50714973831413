"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _brand = require("@/api/brand");

var _index = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Brand',
  filters: {
    timeFilter: function timeFilter(time) {
      return (0, _index.parseTime)(time);
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      tableItemKey: 1,
      list: null,
      total: null,
      loading: true,
      listQuery: {
        page: 1,
        size: 20,
        id: null,
        name: null,
        phone: null
      },
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogStatus: '',
      dialogFormVisible: false,
      formData: {
        id: null,
        name: null,
        phone: null
      },
      rules: {
        name: [{
          required: true,
          message: '姓名必填',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;

      this.loading = true;
      (0, _brand.getList)(this.listQuery).then(function (res) {
        _this.list = res.data.records;
        _this.total = parseInt(res.data.total);
        _this.loading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.fetchList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.size = val;
      this.fetchList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.fetchList();
    },
    resetFormData: function resetFormData() {
      this.formData = {
        id: null,
        name: null,
        phone: null
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.resetFormData();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _brand.create)(_this3.formData).then(function (res) {
            _this3.fetchList();

            _this3.dialogFormVisible = false;

            _this3.$notify({
              title: '成功',
              message: '添加成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    copyFormData: function copyFormData(row) {
      this.formData = {
        id: row.id,
        name: row.name,
        phone: row.phone
      };
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.copyFormData(row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData(id) {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _brand.update)(id, _this5.formData).then(function (res) {
            _this5.fetchList();

            _this5.dialogFormVisible = false;

            _this5.$notify({
              title: '成功',
              message: '更新成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    handleDel: function handleDel(row) {
      var _this6 = this;

      (0, _brand.del)(row.id).then(function (res) {
        _this6.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success',
          duration: 2000
        });

        var index = _this6.list.indexOf(row);

        _this6.list.splice(index, 1);
      });
    }
  }
};
exports.default = _default;