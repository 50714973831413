var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: { placeholder: "请输入编号" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.id,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "id", $$v)
              },
              expression: "listQuery.id"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: { placeholder: "请输入名称" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.name,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "name", $$v)
              },
              expression: "listQuery.name"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "150px" },
              attrs: { placeholder: "请选择是否在售", clearable: "" },
              model: {
                value: _vm.listQuery.saleFlag,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "saleFlag", $$v)
                },
                expression: "listQuery.saleFlag"
              }
            },
            [
              _c("el-option", { attrs: { label: "在售", value: true } }, [
                _vm._v("在售")
              ]),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "未售", value: false } }, [
                _vm._v("未售")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("搜索")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v("添加\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            loading: _vm.loading,
            "row-key": "id",
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "编号", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "名称", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.name
                      ? _c("span", [_vm._v(_vm._s(scope.row.name))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "基础价", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.price
                      ? _c("span", [_vm._v(_vm._s(scope.row.price))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "图片", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "200px", height: "100px" },
                      attrs: { src: scope.row.homeImg, fit: "cover" }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "描述", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.description
                      ? _c("span", [_vm._v(_vm._s(scope.row.description))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否在售", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              effect: "dark",
                              type: scope.row.saleFlag ? "success" : "danger"
                            }
                          },
                          [_vm._v(_vm._s(scope.row.saleFlag ? "在售" : "未售"))]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", width: "300" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.createTime
                      ? [
                          _c("i", { staticClass: "el-icon-time" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("timeFilter")(scope.row.createTime))
                            )
                          ])
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "min-width": "200",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          size: "mini",
                          icon: "el-icon-delete"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDel(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          size: "mini",
                          icon: "el-icon-setting"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleSku(scope.row)
                          }
                        }
                      },
                      [_vm._v("配置规格")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.size,
              "page-sizes": [20, 50, 100, 150],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "70%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.formData,
                "label-position": "left",
                "label-width": "160px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "首页图：", prop: "homeImg" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "img-uploader",
                      attrs: {
                        "show-file-list": false,
                        "before-upload": _vm.beforeUploadImg,
                        action: "#"
                      }
                    },
                    [
                      _vm.formData.homeImg
                        ? _c("img", {
                            staticClass: "img-preview",
                            attrs: { src: _vm.formData.homeImg }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus img-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "头图：", prop: "headImg" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "img-uploader",
                      attrs: {
                        "show-file-list": false,
                        "before-upload": _vm.beforeUploadHeadImg,
                        action: "#"
                      }
                    },
                    [
                      _vm.formData.headImg
                        ? _c("img", {
                            staticClass: "img-preview",
                            attrs: { src: _vm.formData.headImg }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus img-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "40%" },
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "基础价：", prop: "price" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: { min: 0, placeholder: "请输入基础价" },
                    model: {
                      value: _vm.formData.price,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "price", $$v)
                      },
                      expression: "formData.price"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否在售：", prop: "saleFlag" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "请选择是否在售", clearable: "" },
                      model: {
                        value: _vm.formData.saleFlag,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "saleFlag", $$v)
                        },
                        expression: "formData.saleFlag"
                      }
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "在售", value: true } },
                        [_vm._v("在售")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-option",
                        { attrs: { label: "未售", value: false } },
                        [_vm._v("未售")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序号：", prop: "sortNum" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: { min: 1, max: 9999, placeholder: "请输入排序号" },
                    model: {
                      value: _vm.formData.sortNum,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "sortNum", $$v)
                      },
                      expression: "formData.sortNum"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "描述：", prop: "description" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 8 },
                      placeholder: "请输入商品描述，200字以内"
                    },
                    model: {
                      value: _vm.formData.description,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "description", $$v)
                      },
                      expression: "formData.description"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情图：", prop: "detailList" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "#",
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "before-upload": _vm.beforeUploadDetailImg,
                        "file-list": _vm.formData.detailList,
                        "list-type": "picture-card"
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v("只能上传jpg/png文件，且不超过500kb")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData }
                    },
                    [_vm._v("确认")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.updateData(_vm.formData.id)
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配置商品SKU",
            visible: _vm.dialogSkuVisible,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSkuVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "skuForm",
              staticStyle: { width: "100%" },
              attrs: { "label-position": "top", "label-width": "160px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择商品属性：", prop: "attrList" } },
                [
                  _c("el-cascader-panel", {
                    attrs: {
                      options: _vm.attrList,
                      props: _vm.attrProps,
                      row: 10,
                      clearable: ""
                    },
                    on: { change: _vm.handleAttrChange },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var node = ref.node
                          var data = ref.data
                          return [_c("span", [_vm._v(_vm._s(data.name))])]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.attrValList,
                      callback: function($$v) {
                        _vm.attrValList = $$v
                      },
                      expression: "attrValList"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.createTable } },
                [_vm._v("生成属性配置表格")]
              ),
              _vm._v(" "),
              _vm.productAttrList && _vm.productAttrList.length > 0
                ? _c(
                    "el-table",
                    {
                      key: _vm.tableItemKey,
                      staticStyle: { "margin-top": "15px", width: "100%" },
                      attrs: {
                        data: _vm.productAttrList,
                        loading: _vm.loading,
                        "row-key": "id",
                        "element-loading-text": "给我一点时间",
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                        "span-method": _vm.spanMethod
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "attributeId",
                          label: "属性",
                          "min-width": "100"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.attributeName))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          796325693
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "attributeInput",
                          label: "属性值",
                          "min-width": "150"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.attributeInput))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3940923308
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "price",
                          label: "属性价",
                          width: "200"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input-number", {
                                    attrs: { min: 0 },
                                    model: {
                                      value: scope.row.price,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "price", $$v)
                                      },
                                      expression: "scope.row.price"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2954709978
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "attributeStore",
                          label: "库存",
                          width: "200"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input-number", {
                                    attrs: { min: 0 },
                                    model: {
                                      value: scope.row.attributeStore,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "attributeStore",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.attributeStore"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1219206036
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "previewImg",
                          label: "属性图",
                          width: "120",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "image-uploader",
                                      attrs: {
                                        action: "#",
                                        "show-file-list": false,
                                        "before-upload": _vm.beforeUploadAttrImg
                                      }
                                    },
                                    [
                                      scope.row.attributeImg
                                        ? _c("img", {
                                            staticClass: "image",
                                            attrs: {
                                              src: scope.row.attributeImg
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.uploadAttrImg(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              }
                                            }
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-plus image-uploader-icon",
                                            on: {
                                              click: function($event) {
                                                return _vm.uploadAttrImg(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              }
                                            }
                                          })
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          762578913
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogSkuVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.createSku()
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }