"use strict";

var _interopRequireDefault = require("/Users/lingo/projects/changzhen/stylus-manager/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getAttrList = getAttrList;
exports.create = create;
exports.update = update;
exports.del = del;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/product/attribute/list',
    method: 'get',
    params: params
  });
}

function getAttrList() {
  return (0, _request.default)({
    url: '/product/attribute/filter/list',
    method: 'get'
  });
}

function create(data) {
  return (0, _request.default)({
    url: '/product/attribute',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/product/attribute/' + id,
    method: 'put',
    data: data
  });
}

function del(id) {
  return (0, _request.default)({
    url: '/product/attribute/' + id,
    method: 'delete'
  });
}