"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _product = require("@/api/product");

var _attr = require("@/api/attr");

var _index = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Product',
  filters: {
    timeFilter: function timeFilter(time) {
      return (0, _index.parseTime)(time);
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      tableItemKey: 1,
      list: null,
      total: null,
      loading: true,
      listQuery: {
        page: 1,
        size: 20,
        id: null,
        name: null,
        saleFlag: null
      },
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogStatus: '',
      dialogFormVisible: false,
      dialogSkuVisible: false,
      formData: {
        id: null,
        name: null,
        price: null,
        homeImg: null,
        headImg: null,
        saleFlag: null,
        sortNum: null,
        description: null,
        detailList: []
      },
      attrList: null,
      attrMap: null,
      attrValList: null,
      attrImgIndex: null,
      attrProps: {
        multiple: true,
        value: 'id',
        label: 'name',
        children: 'inputVal'
      },
      nameSpan: [],
      namePos: null,
      productAttrList: [],
      productId: null,
      rules: {
        homeImg: [{
          required: true,
          message: '首页图片必传',
          trigger: 'blur'
        }],
        headImg: [{
          required: true,
          message: '头图必传',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '名称必填',
          trigger: 'blur'
        }],
        saleFlag: [{
          required: true,
          message: '是否在售必填',
          trigger: 'blur'
        }],
        sortNum: [{
          required: true,
          message: '排序号必填',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.fetchList();
    this.fetchAttrList();
  },
  methods: {
    fetchProductAttrList: function fetchProductAttrList(id) {
      var _this = this;

      (0, _product.getProductAttrList)(id).then(function (res) {
        _this.productAttrList = res.data;
        _this.attrValList = _this.productAttrList.map(function (pa) {
          return [pa.attributeId, pa.attributeInput];
        });

        _this.mergerData();
      });
    },
    mergerInit: function mergerInit() {
      this.nameSpan = [];
      this.namePos = 0;
    },
    createTable: function createTable() {
      var _this2 = this;

      if (this.productAttrList && this.productAttrList.length > 0) {
        var newArr = this.attrValList.slice(0);
        var newProductArr = this.productAttrList.slice(0);
        newProductArr.forEach(function (val, index) {
          var arr = [val.attributeId, val.attributeInput];
          var arrIndex = (0, _index.getArrIndexOf)(newArr, arr);

          if (arrIndex < 0) {
            _this2.productAttrList.splice(index, 1);
          } else {
            newArr.splice(arrIndex, 1);
          }
        });

        if (newArr && newArr.length > 0) {
          this.productAttrList = this.productAttrList.concat(newArr.map(function (attrVal) {
            return {
              id: null,
              attributeId: attrVal[0],
              productId: null,
              attributeName: _this2.attrMap[attrVal[0]].name,
              attributeSortNum: _this2.attrMap[attrVal[0]].sortNum,
              attributeStore: 0,
              attributeInput: attrVal[1],
              price: 0,
              attributeImg: null
            };
          }));
        }
      } else {
        // 创建新的属性
        this.productAttrList = [];

        if (this.attrValList && this.attrValList.length > 0) {
          this.productAttrList = this.attrValList.map(function (attrVal) {
            return {
              id: null,
              attributeId: attrVal[0],
              productId: null,
              attributeName: _this2.attrMap[attrVal[0]].name,
              attributeSortNum: _this2.attrMap[attrVal[0]].sortNum,
              attributeStore: 0,
              attributeInput: attrVal[1],
              price: 0,
              attributeImg: null
            };
          });
        }
      }

      this.mergerData();
    },
    mergerData: function mergerData() {
      this.mergerInit();

      for (var i = 0; i < this.productAttrList.length; i++) {
        if (i === 0) {
          this.nameSpan.push(1);
          this.namePos = 0;
        } else {
          // 第一列判断当前元素与上一个元素是否相同
          var nameFlag = this.productAttrList[i].attributeName === this.productAttrList[i - 1].attributeName;

          if (nameFlag) {
            this.nameSpan[this.namePos] += 1;
            this.nameSpan.push(0);
          } else {
            this.nameSpan.push(1);
            this.namePos = i;
          }
        }
      }
    },
    spanMethod: function spanMethod(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex === 0) {
        var _row1 = this.nameSpan[rowIndex];

        var _col1 = _row1 > 0 ? 1 : 0;

        return {
          rowspan: _row1,
          colspan: _col1
        };
      }
    },
    fetchAttrList: function fetchAttrList() {
      var _this3 = this;

      (0, _attr.getAttrList)().then(function (res) {
        _this3.attrList = res.data;
        _this3.attrMap = _this3.attrList.reduce(function (item, obj) {
          item[obj.id] = obj;
          return item;
        }, {});
      });
    },
    fetchList: function fetchList() {
      var _this4 = this;

      this.loading = true;
      (0, _product.getList)(this.listQuery).then(function (res) {
        _this4.list = res.data.records;
        _this4.total = parseInt(res.data.total);
        _this4.loading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.fetchList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.size = val;
      this.fetchList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.fetchList();
    },
    resetFormData: function resetFormData() {
      this.formData = {
        id: null,
        name: null,
        price: null,
        homeImg: null,
        headImg: null,
        saleFlag: null,
        sortNum: null,
        description: null,
        detailList: []
      };
    },
    handleCreate: function handleCreate() {
      var _this5 = this;

      this.resetFormData();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _product.create)(_this6.formData).then(function (res) {
            _this6.fetchList();

            _this6.dialogFormVisible = false;

            _this6.$notify({
              title: '成功',
              message: '添加成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    copyFormData: function copyFormData(row) {
      this.formData = {
        id: row.id,
        name: row.name,
        price: row.price,
        homeImg: row.homeImg,
        headImg: row.headImg,
        saleFlag: row.saleFlag,
        sortNum: row.sortNum,
        description: row.description,
        detailList: row.detailList
      };
    },
    handleUpdate: function handleUpdate(row) {
      var _this7 = this;

      this.copyFormData(row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this7.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData(id) {
      var _this8 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _product.update)(id, _this8.formData).then(function (res) {
            _this8.fetchList();

            _this8.dialogFormVisible = false;

            _this8.$notify({
              title: '成功',
              message: '更新成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    handleDel: function handleDel(row) {
      var _this9 = this;

      (0, _product.del)(row.id).then(function (res) {
        _this9.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success',
          duration: 2000
        });

        var index = _this9.list.indexOf(row);

        _this9.list.splice(index, 1);
      });
    },
    handleSku: function handleSku(row) {
      this.dialogSkuVisible = true;
      this.attrValList = [];
      this.productId = row.id;
      this.fetchProductAttrList(row.id);
    },
    createSku: function createSku() {
      var _this10 = this;

      (0, _product.saveProductAttrList)(this.productId, this.productAttrList).then(function (res) {
        _this10.fetchList();

        _this10.dialogSkuVisible = false;

        _this10.$notify({
          title: '成功',
          message: '配置成功',
          type: 'success',
          duration: 2000
        });
      });
    },
    handleAttrChange: function handleAttrChange(e) {},
    beforeUploadImg: function beforeUploadImg(file) {
      var _this11 = this;

      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.5;

      if (!isJPG && !isPNG) {
        this.$message.error('图片格式只支持 JPG/PNG !');
        return false;
      }

      if (!isLt2M) {
        this.$message.error('图片大小不能超过 500KB!');
        return false;
      }

      var fd = new window.FormData();
      fd.append('file', file, file.name);
      (0, _product.upload)(fd).then(function (res) {
        _this11.formData.homeImg = res.data;

        _this11.$notify({
          title: '成功',
          message: '上传成功',
          type: 'success',
          duration: 2000
        });
      }).catch(function (err) {
        _this11.$message.error(err);
      });
      return false;
    },
    beforeUploadHeadImg: function beforeUploadHeadImg(file) {
      var _this12 = this;

      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.5;

      if (!isJPG && !isPNG) {
        this.$message.error('图片格式只支持 JPG/PNG !');
        return false;
      }

      if (!isLt2M) {
        this.$message.error('图片大小不能超过 500KB!');
        return false;
      }

      var fd = new window.FormData();
      fd.append('file', file, file.name);
      (0, _product.upload)(fd).then(function (res) {
        _this12.formData.headImg = res.data;

        _this12.$notify({
          title: '成功',
          message: '上传成功',
          type: 'success',
          duration: 2000
        });
      }).catch(function (err) {
        _this12.$message.error(err);
      });
      return false;
    },
    beforeUploadDetailImg: function beforeUploadDetailImg(file) {
      var _this13 = this;

      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.5;

      if (!isJPG && !isPNG) {
        this.$message.error('图片格式只支持 JPG/PNG !');
        return false;
      }

      if (!isLt2M) {
        this.$message.error('图片大小不能超过 500KB!');
        return false;
      }

      var fd = new window.FormData();
      fd.append('file', file, file.name);
      (0, _product.upload)(fd).then(function (res) {
        _this13.formData.detailList.push({
          id: null,
          url: res.data
        });

        _this13.$notify({
          title: '成功',
          message: '上传成功',
          type: 'success',
          duration: 2000
        });
      }).catch(function (err) {
        _this13.$message.error(err);
      });
      return false;
    },
    uploadAttrImg: function uploadAttrImg(row, index) {
      this.attrImgIndex = index;
    },
    beforeUploadAttrImg: function beforeUploadAttrImg(file) {
      var _this14 = this;

      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.5;

      if (!isJPG && !isPNG) {
        this.$message.error('图片格式只支持 JPG/PNG !');
        return false;
      }

      if (!isLt2M) {
        this.$message.error('图片大小不能超过 500KB!');
        return false;
      }

      var fd = new window.FormData();
      fd.append('file', file, file.name);
      (0, _product.upload)(fd).then(function (res) {
        var element = _this14.productAttrList[_this14.attrImgIndex];
        element.attributeImg = res.data;

        _this14.productAttrList.splice(indexInArray, 1, element);

        _this14.$notify({
          title: '成功',
          message: '上传成功',
          type: 'success',
          duration: 2000
        });
      }).catch(function (err) {
        _this14.$message.error(err);
      });
      return false;
    },
    handleRemove: function handleRemove(file, fileList) {
      if (file.id && this.formData.detailList.length > 0) {
        var _indexInArray = this.formData.detailList.indexOf(file);

        this.formData.detailList.splice(_indexInArray, 1);
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success',
          duration: 2000
        });
      }
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    }
  }
};
exports.default = _default;