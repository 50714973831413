"use strict";

var _interopRequireDefault = require("/Users/lingo/projects/changzhen/stylus-manager/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getInfo = getInfo;
exports.setPwd = setPwd;
exports.logout = logout;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(data) {
  return (0, _request.default)({
    url: '/user/login',
    method: 'post',
    data: data
  });
}

function getInfo() {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get'
  });
}

function setPwd(data) {
  return (0, _request.default)({
    url: '/user/set/password',
    method: 'put',
    data: data
  });
}

function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}