var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container calendar-list-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: { placeholder: "请输入订单编号" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.id,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "id", $$v)
              },
              expression: "listQuery.id"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入商品名称" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.productName,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "productName", $$v)
              },
              expression: "listQuery.productName"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: { placeholder: "请输入经销商号" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.brandId,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "brandId", $$v)
              },
              expression: "listQuery.brandId"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "150px" },
            attrs: { placeholder: "请输入经销商姓名" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.brandName,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "brandName", $$v)
              },
              expression: "listQuery.brandName"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "150px" },
              attrs: { placeholder: "请选择订单状态", clearable: "" },
              model: {
                value: _vm.listQuery.state,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "state", $$v)
                },
                expression: "listQuery.state"
              }
            },
            [
              _c("el-option", { attrs: { label: "待付款", value: 0 } }, [
                _vm._v("待付款")
              ]),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "待发货", value: 1 } }, [
                _vm._v("待发货")
              ]),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "待收货", value: 2 } }, [
                _vm._v("待收货")
              ]),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "已完成", value: 3 } }, [
                _vm._v("已完成")
              ]),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "已取消", value: 4 } }, [
                _vm._v("已取消")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("搜索")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            loading: _vm.loading,
            "row-key": "id",
            "element-loading-text": "给我一点时间",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-table-expand",
                        attrs: { "label-position": "left", inline: "" }
                      },
                      [
                        _vm._l(props.row.spec, function(item) {
                          return _c(
                            "el-form-item",
                            {
                              key: item.id,
                              attrs: { label: item.attributeName }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(item.attributeInput) +
                                    ") " +
                                    _vm._s(item.price) +
                                    "元"
                                )
                              ])
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "备注" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.remark))])
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "流水号" } }, [
                          _c("span", [
                            _vm._v(_vm._s(props.row.transactionCode))
                          ])
                        ])
                      ],
                      2
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单编码", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单金额", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.price))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品信息", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "200px", height: "100px" },
                      attrs: { src: scope.row.productImg, fit: "cover" }
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _c("span", [_vm._v(_vm._s(scope.row.productName))])
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "left", label: "经销商信息", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [_vm._v("姓名：" + _vm._s(scope.row.brandName))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("电话：" + _vm._s(scope.row.brandPhone))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "left", label: "收货信息", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [_vm._v("姓名：" + _vm._s(scope.row.addrName))]),
                    _vm._v(" "),
                    _c("p", [_vm._v("电话：" + _vm._s(scope.row.addrPhone))]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "地址：" +
                          _vm._s(scope.row.addrProvince) +
                          _vm._s(scope.row.addrCity) +
                          _vm._s(scope.row.addrRegion)
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(scope.row.addrDetail))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "数量", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "送达时间", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.deliveryTime))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单状态", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      [
                        _c("el-tag", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("stateFilter")(scope.row.state)) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "二维码", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: {
                        width: "200px",
                        height: "200px",
                        "border-radius": "50%"
                      },
                      attrs: { src: scope.row.qrcodeUrl, fit: "cover" }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", width: "300" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.createTime
                      ? [
                          _c("i", { staticClass: "el-icon-time" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("timeFilter")(scope.row.createTime))
                            )
                          ])
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "300",
              fixed: "right",
              "class-name": "small-padding"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-s-promotion"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDelivery(scope.row)
                          }
                        }
                      },
                      [_vm._v("发货")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          size: "mini",
                          icon: "el-icon-picture-outline-round",
                          disabled: !scope.row.reportForms
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleQrcode(scope.row)
                          }
                        }
                      },
                      [_vm._v("生成二维码")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.size,
              "page-sizes": [20, 50, 100, 150],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单发货",
            visible: _vm.dialogFormVisible,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { "label-position": "left", "label-width": "160px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单备注：", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 8 },
                      placeholder: "请输入订单备注，200字以内"
                    },
                    model: {
                      value: _vm.remark,
                      callback: function($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doDelivery } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.editDialog.open, width: "40%" },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.editDialog, "open", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { "label-position": "left", "label-width": "160px" }
            },
            _vm._l(_vm.editDialog.form, function(value, key, index) {
              return _c(
                "el-form-item",
                { key: index, attrs: { label: key } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editDialog.form[key],
                      callback: function($$v) {
                        _vm.$set(_vm.editDialog.form, key, $$v)
                      },
                      expression: "editDialog.form[key]"
                    }
                  })
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editDialog.open = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doEdit } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }