var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "form-container",
          attrs: {
            loading: _vm.loading,
            model: _vm.formData,
            rules: _vm.formRules,
            "auto-complete": "on",
            size: "medium",
            "label-width": "150px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "旧密码", prop: "oldPwd" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: { type: "password", placeholder: "请输入旧密码" },
                model: {
                  value: _vm.formData.oldPwd,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "oldPwd", $$v)
                  },
                  expression: "formData.oldPwd"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "新密码", prop: "newPwd" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: { type: "password", placeholder: "请输入新密码" },
                model: {
                  value: _vm.formData.newPwd,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "newPwd", $$v)
                  },
                  expression: "formData.newPwd"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "重复密码", prop: "againPwd" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: { type: "password", placeholder: "请输入重复密码" },
                model: {
                  value: _vm.formData.againPwd,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "againPwd", $$v)
                  },
                  expression: "formData.againPwd"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { size: "large" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm()
                    }
                  }
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm()
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }