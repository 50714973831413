"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _customer = require("@/api/customer");

var _index = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Customer',
  filters: {
    timeFilter: function timeFilter(time) {
      return (0, _index.parseTime)(time);
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      tableItemKey: 1,
      list: null,
      total: null,
      loading: true,
      listQuery: {
        page: 0,
        size: 10,
        id: null,
        nickname: null
      },
      dialogStatus: '',
      dialogFormVisible: false
    };
  },
  created: function created() {
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;

      this.loading = true;
      (0, _customer.getList)(this.listQuery).then(function (res) {
        _this.list = res.data.records;
        _this.total = parseInt(res.data.total);
        _this.loading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.fetchList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.size = val;
      this.fetchList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.fetchList();
    }
  }
};
exports.default = _default;