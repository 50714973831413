"use strict";

var _interopRequireDefault = require("/Users/lingo/projects/changzhen/stylus-manager/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.delivery = delivery;
exports.genQrcode = genQrcode;
exports.editOrder = editOrder;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: "orders/list",
    method: "get",
    params: params
  });
}

function delivery(id, remark) {
  return (0, _request.default)({
    url: "orders/" + id + "/delivery?remark=" + remark,
    method: "post"
  });
}

function genQrcode(id) {
  return (0, _request.default)({
    url: "orders/" + id + "/qrcode",
    method: "post"
  });
}

function editOrder(id, reportForms) {
  return (0, _request.default)({
    url: "orders/" + id,
    method: "post",
    data: {
      reportForms: reportForms
    }
  });
}