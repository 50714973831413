"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SetPassword',
  data: function data() {
    var validatePass = function validatePass(rule, value, callback) {
      if (value.length < 8) {
        callback(new Error('密码不能小于8位'));
      } else {
        callback();
      }
    };

    return {
      formData: {
        oldPwd: '',
        newPwd: '',
        againPwd: ''
      },
      formRules: {
        oldPwd: [{
          required: true,
          trigger: 'blur',
          validate: validatePass
        }],
        newPwd: [{
          required: true,
          trigger: 'blur',
          validate: validatePass
        }],
        againPwd: [{
          required: true,
          trigger: 'blur',
          validate: validatePass
        }]
      },
      loading: false
    };
  },
  methods: {
    submitForm: function submitForm() {
      var _this = this;

      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          _this.loading = true;
          (0, _user.setPwd)(_this.formData).then(function (res) {
            _this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success',
              duration: 2000
            });

            _this.loading = false;

            _this.$store.dispatch('LogOut').then(function () {
              location.reload(); // 为了重新实例化vue-router对象 避免bug
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm() {
      this.$refs['formData'].resetFields();
    }
  }
};
exports.default = _default;