"use strict";

var _interopRequireDefault = require("/Users/lingo/projects/changzhen/stylus-manager/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.create = create;
exports.update = update;
exports.del = del;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/brand/list',
    method: 'get',
    params: params
  });
}

function create(data) {
  return (0, _request.default)({
    url: '/brand',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/brand/' + id,
    method: 'put',
    data: data
  });
}

function del(id) {
  return (0, _request.default)({
    url: '/brand/' + id,
    method: 'delete'
  });
}