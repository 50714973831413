"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _conf = require("@/api/conf");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Conf',
  data: function data() {
    return {
      formData: {
        deliveryTime: null,
        officialRemark: null,
        afterSales: null,
        brandHistory: null
      }
    };
  },
  created: function created() {
    this.getDetail();
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;

      (0, _conf.getDetail)().then(function (res) {
        _this.formData = res.data;
      });
    },
    submitForm: function submitForm() {
      var _this2 = this;

      (0, _conf.create)(this.formData).then(function (res) {
        _this2.$notify({
          title: '成功',
          message: '保存成功',
          type: 'success',
          duration: 2000
        });
      });
    },
    resetForm: function resetForm() {
      this.$refs['formData'].resetFields();
    }
  }
};
exports.default = _default;