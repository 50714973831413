var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "form-container",
          attrs: {
            model: _vm.formData,
            "auto-complete": "on",
            size: "medium",
            "label-width": "150px",
            "label-position": "top"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "送达时间：", prop: "deliveryTime" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: { placeholder: "请输入送达时间，例：45个工作日" },
                model: {
                  value: _vm.formData.deliveryTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "deliveryTime", $$v)
                  },
                  expression: "formData.deliveryTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "官方备注：", prop: "officialRemark" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 6, maxRows: 8 },
                  placeholder: "请输入官方备注，500字以内"
                },
                model: {
                  value: _vm.formData.officialRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "officialRemark", $$v)
                  },
                  expression: "formData.officialRemark"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "售后服务：", prop: "afterSales" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 6, maxRows: 8 },
                  placeholder: "请输入售后服务"
                },
                model: {
                  value: _vm.formData.afterSales,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "afterSales", $$v)
                  },
                  expression: "formData.afterSales"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "品牌历史：", prop: "brandHistory" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 6, maxRows: 8 },
                  placeholder: "请输入品牌历史"
                },
                model: {
                  value: _vm.formData.brandHistory,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "brandHistory", $$v)
                  },
                  expression: "formData.brandHistory"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { size: "large" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm()
                    }
                  }
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm()
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }